<template>
   <v-main class="py-0" style="position:relative; overflow:auto ">

    <v-sheet
      class="overflow-y-auto"
    >
    <!-- content -->
      <v-container>
        <v-row >
          <v-col cols='12'>
            <v-card-title >
              2. Инструкция о мерах пожарной безопасноти
            </v-card-title>
          </v-col>
          <v-col
          cols="12"
          >
            <v-alert
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
              color="red"
              icon="mdi-alert"
              class="title font-weight-light"
              >
                Инструкция о мерах пожарной безопасности утверждается руководителем
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on }">
                    <span
                      class="blue lighten-4"
                      v-on="on"
                    >организации</span>
                  </template>
                  <span class="pa-5 subtitle-1">Органа государственной власти, органа местного самоуправления, организация независимо от того, кто является учредителем </span>
                </v-tooltip>
                 или инным уполномоченным должностным лицом в отношении
                 <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on }">
                    <span
                      class="blue lighten-4"
                      v-on="on"
                    >каждого здания, сооружения.</span>
                  </template>
                  <span class="pa-5 subtitle-1">Исключение: жилые и садовые дома, хозяйственные постройки, гаражи на садовых земельных участках, <br> на земельных участках для индивидуального жилищного строительства и ведения личного подсобного хозяйства.</span>
                </v-tooltip>

            </v-alert>
          </v-col>
          <v-col
            cols='6'
            class="d-flex flex-column justify-center align-center"
          >
          <v-dialog
            class="d-flex justify-center"
            v-model="dialog"
            max-width="50%"
            max-height="800"
          >
              <template v-slot:activator="{ on }">
                <v-img
                  lazy-src="img/material/instruction.png"
                  max-width="60%"
                  src="img/material/instruction.png"
                  contain
                  v-on="on"
                ></v-img>
              <v-sheet class="d-flex justify-center">
                <v-card flat>

                  <v-card-title class=" title font-weight-light">
                    <v-icon size="30" color="red" class='mr-3'>mdi-magnify-scan</v-icon>
                    Нажмите на изображение для увеличения
                  </v-card-title>
                </v-card>
              </v-sheet>
              </template>
              <v-card>
                <v-card-action class="d-flex justify-end">
                    <v-btn
                      class="ma-2"
                      color="red"
                      dark
                      @click="dialog = false"
                    >
                      Закрыть
                      <v-icon
                        dark
                        right
                      >
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                </v-card-action>

                  <v-img
                    lazy-src="img/material/instruction.png"
                    max-height="800"
                    max-width="100%"
                    src="img/material/instruction.png"
                    contain
                  ></v-img>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col
          cols="6"
          >
          <v-card flat>
            <v-card-text class="title font-weight-light mb-5">
              Инструкция разрабатывается на основе "Правил противопожарного режима" и нормативных правовых актов по БП, исходя из специфики пожарной опасности зданий, сооружений, помещений, технологических процессов, технологического призводство и технологического производственного оборудования.
            </v-card-text>
          </v-card>
          <v-sheet>
            <v-dialog
                v-model="dialogf"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    block
                    color="red"
                    outlined
                    dark
                    v-on="on"
                    x-large
                  >
                    Содержание инструкции
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar
                    dark
                    color="red"
                  >

                    <v-toolbar-title>Содержание инструкции о мерах пожарной безопасности</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          icon
                          dark
                          @click="dialogf = false"
                        >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                <v-container>
                  <v-row class='mt-5 noselect'>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/hotel.svg"
                        max-width="15%"
                        src="img/material/hotel.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Порядок содержания территории, зданий, сооружений и помещений,
                        <v-tooltip bottom color="red">
                          <template v-slot:activator="{ on }">
                            <span
                              class="blue lighten-4"
                              v-on="on"
                            >эвакуационных путей и выходов</span>
                          </template>
                          <span class="pa-5 subtitle-1">В том числе аварйных, а также путей доступа подразделений пожарной охраны на объекты защиты (на этажи, кровлю и др.)</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center justify-center">
                      <v-img
                        lazy-src="img/material/tshirt.svg"
                        max-width="15%"
                        src="img/material/tshirt.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Порядок содержания и хранения спецодежды, ветоши
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/doorway.svg"
                        max-width="15%"
                        src="img/material/doorway.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Порядок осмотра и закрытия помещений по окончании работы
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/inventory.svg"
                        max-width="15%"
                        src="img/material/inventory.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Допустимое количество единовременно находящихся в помещениях сырья, полуфабрикатов и готовой продукции
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/robotic-arm.svg"
                        max-width="15%"
                        src="img/material/robotic-arm.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Мероприятия по обеспечению ПБ технологических процессов при эксплуатации оборудования и производстве пожароопасных работ
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/cleaning.svg"
                        max-width="15%"
                        src="img/material/cleaning.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Порядок и переодичность уборки горючих отходов и пыли, хранение промасленной одежды
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/fire.svg"
                        max-width="15%"
                        src="img/material/fire.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Порядок сбора, хранения и удаления горючих веществ и материалов
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/speedometer.svg"
                        max-width="15%"
                        src="img/material/speedometer.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Предельные показания
                        <v-tooltip bottom color="red">
                          <template v-slot:activator="{ on }">
                            <span
                              class="blue lighten-4"
                              v-on="on"
                            >КИП</span>
                          </template>
                          <span class="pa-5 subtitle-1">Контрольно-измерительные приборы: манометры, термометры и др.</span>
                        </v-tooltip>
                        , отклонение от которых могут вызвать пожар или взрыв
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/cigarette.svg"
                        max-width="15%"
                        src="img/material/cigarette.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        <v-tooltip bottom color="red">
                          <template v-slot:activator="{ on }">
                            <span
                              class="blue lighten-4"
                              v-on="on"
                            >Обязанности действия работников при пожаре</span>
                          </template>
                          <span class="pa-5 subtitle-1">Вызов пожарной охраны, открытие и блокирование в открытом состоянии вращаяющихся дверей и турникетов, а также других устройств, препятствующих свободной эвакуации людей, аварийная остановка технологического оборудувания, отключение вентеляции и электрооборудования, пользование средствами пожаротушения и пожарной автоматики, эвакуация горючих веществ и материальных ценностей, осмотр и приведение в пожаровзрывобезопасное состояние всех помещений предприятия</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/fire-extinguisher.svg"
                        max-width="15%"
                        src="img/material/fire-extinguisher.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Расположение мест для курения, приминения открытого огня, проезда транспорта и проведения пожароопасных работ
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/dippel-oil.svg"
                        max-width="15%"
                        src="img/material/dippel-oil.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Порядок и нормы хранения и транспортировки пожароожароопасных веществ и материалов
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                    <v-card flat class="d-flex align-center">
                      <v-img
                        lazy-src="img/material/human.svg"
                        max-width="15%"
                        src="img/material/human.svg"
                        contain
                        class="d-flex text-center mr-5"
                      ></v-img>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-card-text class="title font-weight-light ml-2">
                        Допустимое количество людей, которые могу одновременно находиться на объекте защиты
                      </v-card-text>
                    </v-card>
                    </v-col>
                  </v-row>
                </v-container>
                </v-card>
              </v-dialog>
          <v-dialog
            transition="dialog-top-transition"
            max-width="80%"
          >
            <template v-slot:activator="{ on}">
              <v-btn
                color="red"
                v-on="on"
                dark
                x-large
                block
                class="mt-5"
              >
              Ответственные за обеспечение ПБ</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card flat>
                <!-- <v-toolbar
                  color="red"
                  dark
                  class="display-1 font-weight-light mb-5"
                >Обязанности ответственных за обечпечение ПБ</v-toolbar> -->
                <v-card-title dark>
                  Обязанности ответственных за обечпечение ПБ:
                </v-card-title>
                <v-card-text>
                  <v-row class="noselect">
                    <v-col
                    cols="6"
                    >
                      <v-card flat class="pa-0">
                        <v-card-text class="title font-weight-light">
                          1. Сообщать о пожаре в пожарную охрану и информировать руководство, дежурные и аварийные службы объекта защиты;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          2. Организавать спасение людей с использованием сил и технических средств;
                        </v-card-text>
                      <v-card-text class="title font-weight-light">
                        3. Проверять включение автоматических
                        <v-tooltip bottom color="red">
                          <template v-slot:activator="{ on }">
                            <span
                              class="blue lighten-4"
                              v-on="on"
                            >систем противопожарной защиты;</span>
                          </template>
                          <span class="pa-5 subtitle-1">систем оповещения людей о пожаре, пожаротушения, противодымной защиты</span>
                        </v-tooltip>
                      </v-card-text>

                        <v-card-text class="title font-weight-light">
                          4. Выполнять
                          <v-tooltip bottom color="red">
                            <template v-slot:activator="{ on }">
                              <span
                                class="blue lighten-4"
                                v-on="on"
                              >мероприятия</span>
                            </template>
                            <span class="pa-5 subtitle-1">отключение электроэнергии (за исключением систем противопрожарной защиты), остановку работы транспортирующих устройств, агрегатов, аппаратов, <br> перекрываение сырьевых, газовых, паровых и водных коммуникаций, остановку работы систем вентиляции в аварийном и смежных с ним помещениях</span>
                          </v-tooltip>
                          , способствующие предотвращению развития пожара и задымлению помещений здания;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          5. Прекращать все работы в здании (если это допустимо по тех. процессу производства), кроме работ, связанных с мероприятиями по ликвидации пожара;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          6. Удалять за пределы опасной зоны работников, не задействованных в тушении пожара;
                        </v-card-text>
                        <v-card-text class="title font-weight-light pb-0">
                          7. При необходимости отключать
                          <v-tooltip bottom color="red">
                            <template v-slot:activator="{ on }">
                              <span
                                class="blue lighten-4"
                                v-on="on"
                              >электроэнергию</span>
                            </template>
                            <span class="pa-5 subtitle-1">за исключением систем противопрожарной защиты</span>
                          </v-tooltip>
                          , останавливать работу
                          <v-tooltip bottom color="red">
                            <template v-slot:activator="{ on }">
                              <span
                                class="blue lighten-4"
                                v-on="on"
                              >устройств, агрегатов;</span>
                            </template>
                            <span class="pa-5 subtitle-1">транспортирующих устройств, устройств с применением отркытого пламени, а также теплогенерирующих агрегатов, аппаратов и устройств с применением горючих теплоносителей и или с температурой на их внешней поверхности, способной превысить (в том числе при неисправности теплогенерирующего аппарата) 90 С°</span>
                          </v-tooltip>
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            8. Руководить тушением пожара до прибытия пожарной охраны;
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                     <v-card flat class="pa-5">
                        <v-card-text class="title font-weight-light">
                            9. Обеспечивать соблюдение требований безопасности работниками, принимающими участие в тушении пожара;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            10. Организовать эвакуацию и защиту материальных ценностей;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            11. Встречать пожарную охрану и помогать в выборе кратчайшего пути для подъезда к очагу пожара;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            12. Сообщать пожарной охране сведения, необходимые для обеспечения безопасности личного состава, о перерабатываемых или хранящихся на объекте защиты опасных (взрывоопасных), взрывчатых, сильнодействующих, ядовитых веществах;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            13. Сообщать руководителю тушения пожара
                            <v-tooltip bottom color="red">
                              <template v-slot:activator="{ on }">
                                <span
                                  class="blue lighten-4"
                                  v-on="on"
                                >сведения</span>
                              </template>
                              <span class="pa-5 subtitle-1">о конструктивных и технологических особенностях объекта защиты прилегающих строений и сооружений, <br> о количестве и пожароопасных свойствах хранимых и применяемых на объекте защиты веществ, материалов, изделий</span>
                            </v-tooltip>
                            , необходимые для успешной ликвидации пожара;
                        </v-card-text>
                        <v-card-text class="title font-weight-light pb-0">
                            14. Привлекать силы и средства объекта защиты к осуществлению мероприятий по ликвидации пожара и предупреждению его развитию.
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    class="red"
                    dark
                    outlined
                    @click="dialog.value = false"
                  >Закрыть
                    <!-- <v-icon
                      dark
                      right
                      color="white"
                    >mdi-cancel
                    </v-icon> -->
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          </v-sheet>
          <Hint></Hint>
          </v-col>
        </v-row>
      </v-container>
        <!-- content -->
    </v-sheet>
  </v-main>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    drawer: null,
    dialog: false,
    dialogf: false,
    selectedItem: 1,
    items: [
      { text: '<span>1. Нормативные документы,<br> регламентирующие требования ПБ</span>', icon: 'mdi-flag' },
      { text: '<span>2. Инструкция о мерах пожарной <br> безопасности</span>', icon: 'mdi-flag' },
      { text: '<span>1. Нормативные документы,<br> регламентирующие требования ПБ</span>', icon: 'mdi-flag' }
    ]
  }),
  components: {
    Hint
  }
}
</script>

<style scoped>
.ptm-arrow-block {
  position: absolute;
  right: 1%;
  bottom: 3%;
}
.ptm-progress-block {
  position: absolute;
  right: 1%;
  top: 7%;
}
</style>
